/**
 * FarmsEditModal.jsx
 * --------------------
 * Modal component that contains functionality for editing farms in the LWR database.
 * This data update is done via a PUT request to the LWR API.
 *
 * Contains update form and assosciated validation.
 *
 * Author: Oscar Jaimes
 * Last Updated: 15/06/2021
 */

import React, { useState, useRef } from "react";
import { Modal, Button, Alert, Form, Row, Col } from "react-bootstrap";

// farm api
import { editFarm } from "../../../api/farms";

export default function FarmsEditModal(props) {
  // State vars
  const [show, setShow] = useState(false);
  const [err, setErr] = useState("");
  const [success, setSuccess] = useState("");

  // Refs
  const farmNameRef = useRef();
  const contactNameRef = useRef();
  const contactNumberRef = useRef();
  const contactEmailRef = useRef();
  const addressRef = useRef();
  const regionCodeRef = useRef();
  const latRef = useRef();
  const longRef = useRef();
  const numSytsemRef = useRef();

  const handleClose = () => {
    setShow(false);
    setErr("");
    setSuccess("");
    props.onEnd();
  };
  const handleShow = () => setShow(true);

  const edit = async (e) => {
    e.preventDefault();

    const bodyValues = {
      name: props.farm.name,
      owner: contactNameRef.current.value,
      contactNumber: contactNumberRef.current.value,
      contactEmail: contactEmailRef.current.value,
      address: addressRef.current.value,
      lat: latRef.current.value,
      long: longRef.current.value,
      numSystems: numSytsemRef.current.value,
      regionId: regionCodeRef.current.value,
    };

    const editFarmResult = await editFarm(bodyValues);
    if (editFarmResult.status != 200) {
      setErr("Error updating farm");
    } else {
      setSuccess("Succesfully updated farm. You may now close this modal.");
    }

  };

  return (
    <>
      <Modal show={props.show} onHide={handleClose} size="lg" style={{ zoom: '85%', marginTop: '2%' }}>
        <Modal.Header>
          <Modal.Title style={{ color: '#005E7D' }}>View/Edit Farm - {props.farm.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <Col>
                {err && <Alert variant="danger">{err}</Alert>}
                {success && <Alert variant="success">{success}</Alert>}
              </Col>
            </Row>
            <Row>
              <Form onSubmit={edit}>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label style={{ color: '#005E7D' }}>Farm Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter farm name"
                        ref={farmNameRef}
                        defaultValue={props.farm.name}
                        disabled={true}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label style={{ color: '#005E7D' }}>Parent Organization</Form.Label>
                      <Form.Control as="select" disabled={true}>
                        <option>{props.farm.organizationId}</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <br />
                <Form.Group>
                  <Form.Label style={{ color: '#005E7D' }}>Main Contact</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter contact name"
                    ref={contactNameRef}
                    defaultValue={props.farm.owner}
                    required
                  />
                </Form.Group>
                <br />
                <Form.Group>
                  <Form.Label style={{ color: '#005E7D' }}>Contact Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter contact Email"
                    ref={contactEmailRef}
                    defaultValue={props.farm.contactEmail}
                    required
                  />
                </Form.Group>
                <br />
                <Form.Group>
                  <Form.Label style={{ color: '#005E7D' }}>Contact Number</Form.Label>
                  <Form.Control
                    type="tel"
                    placeholder="Enter contact number"
                    ref={contactNumberRef}
                    defaultValue={props.farm.contactNumber}
                    required
                  />
                </Form.Group>
                <br />
                <Form.Group>
                  <Form.Label style={{ color: '#005E7D' }}>Address</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    placeholder="Enter farm address"
                    ref={addressRef}
                    defaultValue={props.farm.address}
                    required
                  />
                </Form.Group>
                <br />
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label style={{ color: '#005E7D' }}>Region Code</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="region code"
                        ref={regionCodeRef}
                        defaultValue={props.farm.regionId}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label style={{ color: '#005E7D' }}>Latitude</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="latitude"
                        ref={latRef}
                        defaultValue={props.farm.lat}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label style={{ color: '#005E7D' }}>Longitude</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="longitude"
                        ref={longRef}
                        defaultValue={props.farm.long}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <br />
                <Form.Group>
                  <Form.Label style={{ color: '#005E7D' }}>Number of systems</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Number of systems"
                    ref={numSytsemRef}
                    defaultValue={props.farm.numSystems}
                    required
                  />
                </Form.Group>
                <br />
                <Row>
                  <Col>
                    <Button variant="outline-dark" type="submit">
                      Update
                    </Button>
                  </Col>
                  <Col style={{ textAlign: 'right' }}>
                    <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
